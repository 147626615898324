import "./PartnershipOffer.scss";

import Button from "../widgets/Button/Button";
import EntreLogo from "../../assets/Entre_logo.png";
import Logo from "../../assets/logo.svg";
import React from "react";
import TagIcon from "../../assets/tag-icon.svg";

export const PartnershipOffer = () => {
  return (
    <div className="partnership-container">
      <div className="box-container">
        <div className="box">
          <div className="inner">
            <img src={EntreLogo} alt="AllBright Logo" />
          </div>
        </div>
        <div className="box">
          <div className="inner">
              <div style={{"width": "140px"}}>
                  <Logo/>
              </div>
          </div>
        </div>
      </div>
      <div className="partnership-offer">
        <p>AllBright and Entre partnership offer:</p>
        <div className="text-with-icon">
          <div class={"text-with-icon__tag-icon"}>
              <TagIcon/>
          </div>
          <p className="text-with-icon__text">
            Get <strong>2500€</strong> worth consultation{" "}
            <strong>for free</strong>
          </p>
        </div>
        <div className="text-with-icon">
            <div className={"text-with-icon__tag-icon"}>
                <TagIcon/>
            </div>
          <p className="text-with-icon__text">
            Get <strong>15%</strong> off for building a mobile and web app with
            us
          </p>
        </div>
      </div>
      <Button
        text="REDEEM YOUR COUPON"
        onClick={() => {
          const formButton: HTMLElement | null = document.getElementById(
            "entre-coupon-form"
          );
          if (formButton) {
            formButton.click();
          }
        }}
      />
      <span className="partnership-container__sidenote">
        * limited offer for the Entre members only
      </span>
    </div>
  );
};
