import React from "react";

import "./HowYouCanUse.scss";

import MuscleIcon from "../../assets/muscle.svg";
import LaptopIcon from "../../assets/laptop.svg";
import PhoneIcon from "../../assets/phone.svg";
import FigmaIcon from "../../assets/Figma.svg";
import JamstackIcon from "../../assets/Jamstack.png";
import FlutterIcon from "../../assets/Flutter.svg";

export const HowYouCanUse = () => {
  return (
  <div className="how-you-can-use-container">
    <h2>How you can use the coupon</h2>
    <div className="how-you-can-use-container__grey-boxes-container">
      <div className="how-you-can-use-container__grey-boxes-container__grey-box">
        <div className={"box-icon"}>
          <MuscleIcon/>
        </div>
        <span>Product design UX, UI</span>
      </div>
      <div className="how-you-can-use-container__grey-boxes-container__grey-box">
        <div className={"box-icon"}>
          <LaptopIcon/>
        </div>
        <span>Custom web apps with CMS</span>
      </div>
      <div className="how-you-can-use-container__grey-boxes-container__grey-box">
        <div className={"box-icon"}>
          <PhoneIcon/>
        </div>
        <span>Cross platform mobile applications</span>
      </div>
    </div>
    <p>We are totally in love in:</p>
    <div className="how-you-can-use-container__black-boxes-container">
      <div className="how-you-can-use-container__black-boxes-container__black-box">
        <div className={"box-icon"}>
          <FigmaIcon/>
        </div>
        <span>Figma</span>
      </div>
      <div className="how-you-can-use-container__black-boxes-container__black-box">
        <img className="box-icon" src={JamstackIcon} alt="" />
        <span>Jamstack</span>
      </div>
      <div className="how-you-can-use-container__black-boxes-container__black-box">
        <div className={"box-icon"}>
          <FlutterIcon/>
        </div>
        <span>Flutter</span>
      </div>
    </div>
  </div>
)};