import React from "react";
import * as Scroll from "react-scroll";

import { ContinueReading } from "../../components/ContinueReading/ContinueReading";
import { HowYouCanUse } from "../../components/HowYouCanUse/HowYouCanUse";
import { Landing } from "../../components/Landing/Landing";
import { OurPortfolioEntre } from "../../components/Portfolio/OurPortfolioEntre";
import { PartnershipOffer } from "../../components/PartnershipOffer/PartnershipOffer";

function EntrePage() {
  return (
    <>
      <div className="sections">
        <Scroll.Element name="landing">
          <section>
            <Landing
              headerText="Build mobile and web applications with All Bright.io"
              smallerText
              scrollTo="partnership-offer"
              scrollOffset={
                typeof window !== "undefined" && -(window.innerHeight / 4 - 120)
              }
            />
          </section>
        </Scroll.Element>
        <Scroll.Element name="partnership-offer">
          <section>
            <PartnershipOffer />
          </section>
        </Scroll.Element>

        <section>
          <HowYouCanUse />
        </section>
        <section>
          <OurPortfolioEntre />
        </section>

        <section>
          <ContinueReading
            nextSection={"Redeem your coupon"}
            link="blog"
            onClick={() => {
              const formButton: HTMLElement | null = document.getElementById(
                "entre-coupon-form"
              );
              if (formButton) {
                formButton.click();
              }
            }}
          />
        </section>
      </div>
    </>
  );
}

export default EntrePage;
